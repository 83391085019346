import React, {useEffect} from 'react';
import moment from 'moment';
import {truncate} from 'lodash';
import {NewspaperIcon} from '@heroicons/react/outline';

import DocumentHeader from '../../components/DocumentHeader';
import {Breadcrumb} from '../../components/LinkedData';
import Link from '../../components/Link';
import {useAnalytics} from '../../hooks';
import Breadcrumbs from '../../components/Breadcrumbs';

export default function Page({posts, tag}) {
  const {tracker} = useAnalytics();

  useEffect(() => {
    tracker.blogView({tag: tag?.slug});
  }, []);

  return (
    <>
      <DocumentHeader
        title={tag ? `${tag.name} Posts` : 'Exponent Blog'}
        metaDescription={tag
          ? `Read our latest blog posts about ${tag.name} on Exponent.`
          : 'Read our latest blog posts on product management, software engineering, and other tech roles.'}
      />
      {tag && (
        <Breadcrumb
          items={[
            {name: 'Blog', url: '/blog'},
            {name: tag.name, url: `/blog/tag/${tag.slug}`},
          ]}
        />
      )}
      <div className="bg-white pt-20 py-24 sm:pt-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            {tag && (
              <Breadcrumbs
                className="justify-center mb-4"
                items={[
                  {name: 'Blog', url: '/blog'},
                  {name: tag.name, url: `/blog/tag/${tag.slug}`},
                ]}
              />
            )}
            <h1 className="text-3xl font-bold tracking-tight text-black sm:text-5xl">
              {tag ? `${tag.name} Posts` : 'Exponent Blog'}
            </h1>
            <h2 className="mt-2 text-lg leading-8 text-gray-600">
              {tag
                ? `Read our latest blog posts about ${tag.name} on Exponent.`
                : 'Read our latest blog posts on product management, software engineering, and other tech roles.'}
            </h2>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article key={post.id} className="flex flex-col items-start justify-start">
                <div className="relative w-full">
                  <Link href={`/blog/${post.slug}`}>
                    {post.feature_image ? (
                      <img
                        src={post.feature_image}
                        alt={post.title}
                        className="h-40 lg:h-48 w-full rounded-2xl bg-gray-100 object-cover"
                        loading="lazy"
                      />
                    ) : (
                      <div className="h-40 lg:h-48 w-full rounded-2xl bg-indigo-100 flex items-center justify-center text-indigo-600">
                        <NewspaperIcon className="w-12 h-12" />
                      </div>
                    )}
                  </Link>
                </div>
                <div className="flex flex-col justify-between max-w-xl">
                  <div className="mt-8 flex items-center gap-x-4 text-xs">
                    <time dateTime={post.published_at} className="text-gray-500">
                      {moment(post.published_at).fromNow()}
                    </time>
                    <Link
                      href={`/blog/tag/${post.primary_tag?.slug}`}
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {post.primary_tag?.name}
                    </Link>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <Link href={`/blog/${post.slug}`}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </Link>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{truncate(post.excerpt, {length: 150})}</p>
                  </div>
                  <div className="relative mt-8 flex items-center gap-x-4">
                    <img
                      src={post.primary_author?.profile_image}
                      alt=""
                      className="h-10 w-10 rounded-full bg-gray-100"
                      loading="lazy"
                    />
                    <div className="text-sm leading-6">
                      <p className="font-semibold text-gray-900">
                        <span className="absolute inset-0" />
                        {post.primary_author?.name}
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

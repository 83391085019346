import BlogPage from '../../js/pages/BlogPage';

import {getPosts} from '../../../server/services/blog';

export default BlogPage;

export async function getStaticProps({params}) {
  const posts = await getPosts();

  return {
    props: {posts},
    revalidate: 60,
  };
}

import React from 'react';
import cx from 'classnames';

import Link from './Link';
import {CaretIcon} from './svg';

const Breadcrumbs = ({items = [], className}) => (
  <div className={cx('flex items-center space-x-3 overflow-scroll scrollbar-hidden', className)}>
    {items.map(({url, name, truncate}, i) => (
      <>
        <Link href={url} className={cx('whitespace-nowrap font-medium text-gray-600', {truncate})}>
          {name}
        </Link>
        {i < items.length - 1 && (
          <CaretIcon height={9} width={6} fill="#999" className="flex-shrink-0 transform -rotate-180" />
        )}
      </>
    ))}
  </div>
);

export default Breadcrumbs;
